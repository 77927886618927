import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createTextVNode as _createTextVNode, createVNode as _createVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

import { ref, inject, onMounted, watch } from "vue";
import ErrorPopup from "@/components/elements/ErrorPopup.vue";
import useErrorPopup from "@/composables/useErrorPopup";
import { transformPayload } from "./transform-payload";
import { api } from "@/api";
import { ErrorResponse, HashResponse, MQ } from "@/types";
import { useRouter } from "vue-router";
import { scrollToTop } from "@/utils/scroll";
import useSlides from "../../../../composables/useSlides";
import { OPTION_SLUGS, SLUGS } from "@/data/constants";
import { useUiStore } from "@/store/ui";
import { useOptionsStore } from "@/store/options";
import { usePersonalInfoStore } from "@/store/personal-info";
import { useExamStore } from "@/store/exam";
import {
    reExamCheckoutRoute,
    onlineCheckoutRoute,
    offlineCheckoutRoute,
} from "@/router/routes/checkout";
import useQuery from "@/composables/useQuery";


export default /*@__PURE__*/_defineComponent({
  __name: 'SliderTools',
  setup(__props) {

const uiStore = useUiStore();
const optionsStore = useOptionsStore();
const personalInfoStore = usePersonalInfoStore();
const examStore = useExamStore();
const mq: MQ | undefined = inject("mq");
const router = useRouter();
const { updateUrl } = useQuery();

const { displayPopup, message, closePopup, openPopup, enableHelpdesk } =
    useErrorPopup();

const { slides, currentSlide, passesRule } = useSlides();

const isQuerying = ref(false);

const previous = () => {
    uiStore.previousSlide();
    updateUrl();
    setTimeout(() => {
        scrollToTop("main");
    }, 100);
};

const next = () => {
    uiStore.nextSlide();
    updateUrl();
    setTimeout(() => {
        scrollToTop("main");
    }, 100);
};

const getCheckoutRoute = () => {
    // upsell never comes from within this application
    if (personalInfoStore.subscription_type === "re-exam") {
        return reExamCheckoutRoute.name;
    } else if (
        optionsStore.selection[SLUGS.COURSE_TYPES]?.slug === OPTION_SLUGS.ONLINE
    ) {
        return onlineCheckoutRoute.name;
    } else {
        return offlineCheckoutRoute.name;
    }
};

const submit = () => {
    const payload = transformPayload(
        optionsStore,
        personalInfoStore,
        examStore,
        uiStore
    );
    isQuerying.value = true;
    api.register(payload)
        .then((response: HashResponse) => {
            isQuerying.value = false;
            router.push({
                name: getCheckoutRoute(),
                query: { hash: response.hash },
            });
        })
        .catch((error: ErrorResponse) => {
            isQuerying.value = false;
            openPopup(error);
        });
};

watch(
    () => examStore.selection.dates.method,
    () => {
        if (
            examStore.selection.dates.method &&
            examStore.selection.dates.method.slug === "asap"
        ) {
            next();
        }
    }
);

onMounted(() => {
    // let readUrl in Planner go first
    setTimeout(() => {
        updateUrl();
    }, 1000);
});

return (_ctx: any,_cache: any) => {
  const _component_etd_button = _resolveComponent("etd-button")!
  const _component_etd_container = _resolveComponent("etd-container")!
  const _component_etd_loading_cover = _resolveComponent("etd-loading-cover")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_etd_container, {
      gap: "xl",
      justify: "space-between",
      direction: "row",
      align: "center"
    }, {
      default: _withCtx(() => [
        (_unref(currentSlide) > 0)
          ? (_openBlock(), _createBlock(_component_etd_button, {
              key: 0,
              id: "previous-slide",
              onClick: previous,
              "icon-id": "arrow-left",
              variant: "secondary",
              theme: "white"
            }, {
              default: _withCtx(() => [
                (_unref(mq)?.t !== 's')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.$t("back")), 1))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_unref(mq)?.t !== 's' && _unref(currentSlide) === 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2))
          : _createCommentVNode("", true),
        (_unref(currentSlide) === _unref(slides).length - 1)
          ? (_openBlock(), _createBlock(_component_etd_button, {
              key: 2,
              id: "next-slide",
              onClick: submit,
              disabled: !_unref(passesRule)
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("goto_payment")), 1)
              ]),
              _: 1
            }, 8, ["disabled"]))
          : (_openBlock(), _createBlock(_component_etd_button, {
              key: 3,
              id: "next-slide",
              onClick: next,
              disabled: !_unref(passesRule)
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("continue")), 1)
              ]),
              _: 1
            }, 8, ["disabled"]))
      ]),
      _: 1
    }),
    (isQuerying.value)
      ? (_openBlock(), _createBlock(_component_etd_loading_cover, { key: 0 }))
      : _createCommentVNode("", true),
    (_unref(displayPopup))
      ? (_openBlock(), _createBlock(ErrorPopup, {
          key: 1,
          onClose: _unref(closePopup),
          "enable-helpdesk": _unref(enableHelpdesk)
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_unref(message)), 1)
          ]),
          _: 1
        }, 8, ["onClose", "enable-helpdesk"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})