import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "SliderProgress" }
const _hoisted_2 = { class: "SliderProgress__label" }
const _hoisted_3 = { class: "SliderProgress__step" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_etd_progress = _resolveComponent("etd-progress")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, _toDisplayString(_ctx.$t("subscribe")), 1),
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t("step") +
                    " " +
                    (_ctx.currentSlide + 1) +
                    " " +
                    _ctx.$t("off").toLowerCase() +
                    " " +
                    _ctx.slides.length), 1)
    ]),
    _createVNode(_component_etd_progress, {
      progress: 100 * ((_ctx.currentSlide + 1) / _ctx.slides.length),
      theme: "orange",
      size: "regular"
    }, null, 8, ["progress"])
  ]))
}