import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, withCtx as _withCtx, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  id: "slider-container",
  class: "Slider__container"
}

import { ref, onMounted, watch, computed } from "vue";
import { measureSlider } from "@/utils/measure";

// slides
import Slide from "@/components/pages/planner/slider/Slide.vue";
import SlideProductCategories from "../slides/product-category/SlideProductCategories.vue";
import SlideOnlinePackages from "../slides/online-package/SlideOnlinePackages.vue";
import SlideExamTypes from "../slides/exam-type/SlideExamType.vue";
import SlideCourseTypes from "../slides/course-type/SlideCourseTypes.vue";
import SlideLocations from "../slides/location/SlideLocations.vue";
import SlideCbrLocations from "../slides/cbr-locations/SlideCbrLocations.vue";
import SlideSelfBooking from "../slides/self-booking/SlideSelfBooking.vue";
import SlideDates from "../slides/dates/SlideDates.vue";
import SlidePersonalInfo from "../slides/personal-info/SlidePersonalInfo.vue";
import SlideOverview from "../slides/overview/SlideOverview.vue";
import useSlides from "../../../../composables/useSlides";


export default /*@__PURE__*/_defineComponent({
  __name: 'Slider',
  setup(__props) {

const gap = ref(100);
const mounted = ref(false);
const { slides, currentSlide } = useSlides();

const components = {
    Slide,
    SlideProductCategories,
    SlideOnlinePackages,
    SlideExamTypes,
    SlideCourseTypes,
    SlideLocations,
    SlideCbrLocations,
    SlideSelfBooking,
    SlideDates,
    SlidePersonalInfo,
    SlideOverview,
};

const scrollUp = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
};

watch(
    () => currentSlide.value,
    () => {
        measureSlider(currentSlide.value);
        scrollUp();
    }
);

onMounted(() => {
    mounted.value = true;
    setTimeout(() => {
        measureSlider(currentSlide.value);
    });
    window.onresize = () => {
        measureSlider(currentSlide.value);
    };
});

return (_ctx: any,_cache: any) => {
  const _component_etd_shadow_buffer = _resolveComponent("etd-shadow-buffer")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle({ '--slides': _unref(slides).length, '--gap': gap.value + 'px' }),
    class: "Slider"
  }, [
    _createVNode(_component_etd_shadow_buffer, {
      "buffer-x": 15,
      "buffer-y": 15
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", {
            style: _normalizeStyle({
                        left:
                            'calc(' +
                            -_unref(currentSlide) * 100 +
                            '% - ' +
                            _unref(currentSlide) +
                            ' * var(--gap))',
                    }),
            class: "Slider__slides"
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(slides), (slide, index) => {
              return (_openBlock(), _createBlock(Slide, {
                key: index,
                i: index,
                id: slide.name
              }, {
                default: _withCtx(() => [
                  (_openBlock(), _createBlock(_resolveDynamicComponent(components[slide.name]), {
                    data: slide.data,
                    selection: slide.selection,
                    onSelectDetail: slide.atSelectDetail,
                    onSelect: slide.atSelect
                  }, null, 40, ["data", "selection", "onSelectDetail", "onSelect"]))
                ]),
                _: 2
              }, 1032, ["i", "id"]))
            }), 128))
          ], 4)
        ])
      ]),
      _: 1
    })
  ], 4)), [
    [_vShow, mounted.value]
  ])
}
}

})