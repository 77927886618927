import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { "data-locator": "slide-head" }

import { computed, inject } from "vue";
import fullFormConfig from "./form-config";
import { FormValue, MQ } from "@/types";
import { SLIDE } from "../../../../../data/constants";
import { usePersonalInfoStore } from "@/store/personal-info";


export default /*@__PURE__*/_defineComponent({
  __name: 'SlidePersonalInfo',
  setup(__props) {

const personalInfoStore = usePersonalInfoStore();
const mq: MQ | undefined = inject("mq");
const entry = `slides.${SLIDE.PERSONAL_INFO}.`;

// todo !isReExam dan middelste deel weg

const atUpdateStatus = (status: boolean) =>
    (personalInfoStore.formValid = status);

const atUpdateValue = (form: FormValue) => (personalInfoStore.form = form);

const formConfig = computed(() => {
    return fullFormConfig;
});

return (_ctx: any,_cache: any) => {
  const _component_etd_form = _resolveComponent("etd-form")!
  const _component_etd_container = _resolveComponent("etd-container")!

  return (_openBlock(), _createBlock(_component_etd_container, {
    gap: "xxl",
    style: {"max-width":"800px","margin":"0 auto"}
  }, {
    default: _withCtx(() => [
      _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.$t(entry + "head")), 1),
      _createVNode(_component_etd_form, {
        formValue: _unref(personalInfoStore).form,
        "onUpdate:formValue": [
          _cache[0] || (_cache[0] = ($event: any) => ((_unref(personalInfoStore).form) = $event)),
          atUpdateValue
        ],
        "form-config": formConfig.value,
        unfolded: _unref(mq)?.t === 's',
        onUpdateStatus: atUpdateStatus
      }, null, 8, ["formValue", "form-config", "unfolded"])
    ]),
    _: 1
  }))
}
}

})