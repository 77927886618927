import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = { style: {"padding":"var(--spacing-m)\n                                                var(--spacing-xxl)","background":"var(--color-white)","box-shadow":"0 -2px 2px rgba(33, 37, 41, 0.06)"} }
const _hoisted_3 = {
  key: 1,
  style: {"margin":"auto"}
}

import { computed, inject, ref } from "vue";
import { api } from "@/api";
import { useRoute, useRouter } from "vue-router";
import useErrorPopup from "@/composables/useErrorPopup";
import ErrorPopup from "@/components/elements/ErrorPopup.vue";
import { ErrorResponse, Subscription, InvoicePostMetadata } from "@/types";
import AddDiscount from "./AddDiscount.vue";
import { ICONS_NEW, OPTION_SLUGS, SLUGS } from "@/data/constants";
import Sticky from "@/components/elements/Sticky.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'Subscription',
  emits: ["response"],
  setup(__props, { emit: __emit }) {

const route = useRoute();
const router = useRouter();
const isLoaded = ref(false);
const subscription = ref<Subscription>();
const emit = __emit;

const mq = inject("mq");

const { displayPopup, message, openPopup, closePopup } = useErrorPopup();

const getSubscriptionData = (discountCode: string | null) => {
    api.getSubscriptionData(route.query.hash, discountCode)
        .then((response: Subscription) => {
            subscription.value = response;
            isLoaded.value = true;
            emit("response", response);
        })
        .catch((error: ErrorResponse) => {
            openPopup(error);
            isLoaded.value = true;
        });
};

const removeDiscount = (discountCode: string) => {
    api.removeDiscountCode(route.query.hash, discountCode)
        .then((response: Subscription) => {
            subscription.value = response;
            isLoaded.value = true;
        })
        .catch((error: ErrorResponse) => {
            openPopup(error);
            isLoaded.value = true;
        });
};

const addDiscountCode = (discountCode: string) => {
    getSubscriptionData(discountCode);
};

const invoiceLines = computed(() => {
    const isOnline = (metadata: InvoicePostMetadata) => {
        return (
            metadata[SLUGS.COURSE_TYPES] &&
            metadata[SLUGS.COURSE_TYPES] === OPTION_SLUGS.ONLINE
        );
    };

    const getIcon = (metadata: InvoicePostMetadata) => {
        const productCategorySlug = metadata[SLUGS.PRODUCT_CATEGORIES];
        return ICONS_NEW[productCategorySlug];
    };

    return subscription.value?.invoice_posts.map((invoiceLine) => {
        if (invoiceLine.metadata) {
            let details: any[] = [];
            const offline = {
                icon: "screen-users",
                label: "Theoriecursus in één dag",
            };
            const exams = {
                icon: "",
                label: "CBR oefenexamens",
            };
            const video = {
                icon: "circle-play",
                label: "Videomateriaal",
            };
            const ebook = {
                icon: "book",
                label: "E-book",
            };
            exams.icon = getIcon(invoiceLine.metadata);
            if (isOnline(invoiceLine.metadata)) {
                details = [exams, video, ebook];
            } else {
                details = [offline, exams, ebook];
            }
            return {
                ...invoiceLine,
                details,
            };
        } else {
            return invoiceLine;
        }
    });
});

const hasDiscount = computed(() => {
    return subscription.value?.invoice_posts.some(
        (invoiceLine) => invoiceLine.type === "discount"
    );
});

if (route.query.hash) {
    getSubscriptionData(null);
} else {
    router.push({ name: "Planner" });
}

return (_ctx: any,_cache: any) => {
  const _component_etd_ideal_button = _resolveComponent("etd-ideal-button")!
  const _component_etd_container = _resolveComponent("etd-container")!
  const _component_etd_card = _resolveComponent("etd-card")!
  const _component_etd_receipt = _resolveComponent("etd-receipt")!
  const _component_etd_loading = _resolveComponent("etd-loading")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (isLoaded.value && subscription.value)
      ? (_openBlock(), _createBlock(_component_etd_card, {
          key: 0,
          type: _unref(mq).t === 's' ? 'transparent' : 'regular',
          padding: "xxxxl"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_etd_container, {
              gap: _unref(mq).t === 's' ? 'xl' : 'xxxxl',
              direction: _unref(mq).t === 's' ? 'column' : 'row',
              n: 2
            }, {
              default: _withCtx(() => [
                _createVNode(_component_etd_card, {
                  type: _unref(mq).t === 's' ? 'regular' : 'transparent'
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_etd_container, { gap: "xxxl" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_etd_container, null, {
                          default: _withCtx(() => [
                            _createElementVNode("div", null, [
                              _createElementVNode("h3", null, _toDisplayString(subscription.value.payment_head), 1),
                              _createElementVNode("div", {
                                innerHTML: subscription.value.payment_text
                              }, null, 8, _hoisted_1)
                            ]),
                            (_unref(mq).t === 's')
                              ? (_openBlock(), _createBlock(Sticky, { key: 0 }, {
                                  regular: _withCtx(() => [
                                    _createVNode(_component_etd_ideal_button, {
                                      href: subscription.value.payment_link,
                                      "enable-info": ""
                                    }, null, 8, ["href"])
                                  ]),
                                  sticky: _withCtx(() => [
                                    _createElementVNode("div", _hoisted_2, [
                                      _createVNode(_component_etd_ideal_button, {
                                        href: subscription.value.payment_link
                                      }, null, 8, ["href"])
                                    ])
                                  ]),
                                  _: 1
                                }))
                              : (_openBlock(), _createBlock(_component_etd_ideal_button, {
                                  key: 1,
                                  href: subscription.value.payment_link,
                                  "enable-info": ""
                                }, null, 8, ["href"]))
                          ]),
                          _: 1
                        }),
                        (subscription.value.show_discount && !hasDiscount.value)
                          ? (_openBlock(), _createBlock(AddDiscount, {
                              key: 0,
                              onAddDiscount: addDiscountCode
                            }))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["type"]),
                _createVNode(_component_etd_card, {
                  type: _unref(mq).t === 's' ? 'regular' : 'transparent'
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("h3", null, _toDisplayString(_ctx.$t("pages.subscription.details.head")), 1),
                    _createVNode(_component_etd_receipt, {
                      onRemoveDiscount: removeDiscount,
                      lines: invoiceLines.value,
                      total: subscription.value.total
                    }, null, 8, ["lines", "total"])
                  ]),
                  _: 1
                }, 8, ["type"])
              ]),
              _: 1
            }, 8, ["gap", "direction"])
          ]),
          _: 1
        }, 8, ["type"]))
      : _createCommentVNode("", true),
    (!isLoaded.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_etd_loading)
        ]))
      : _createCommentVNode("", true),
    (_unref(displayPopup))
      ? (_openBlock(), _createBlock(ErrorPopup, {
          key: 2,
          onClose: _unref(closePopup)
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_unref(message)), 1)
          ]),
          _: 1
        }, 8, ["onClose"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})