import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  style: {"margin":"auto"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_etd_logo = _resolveComponent("etd-logo")!
  const _component_helpdesk_button = _resolveComponent("helpdesk-button")!
  const _component_slider_progress = _resolveComponent("slider-progress")!
  const _component_etd_loading = _resolveComponent("etd-loading")!
  const _component_slider = _resolveComponent("slider")!
  const _component_etd_form_error_message = _resolveComponent("etd-form-error-message")!
  const _component_slider_tools = _resolveComponent("slider-tools")!
  const _component_etd_pagewrap = _resolveComponent("etd-pagewrap")!
  const _component_etd_page = _resolveComponent("etd-page")!

  return (_openBlock(), _createBlock(_component_etd_page, {
    "main-full-width": "",
    "hide-intro": "",
    "fixed-content": "",
    "nav-extended": "",
    stacked: _ctx.mq.t === 's'
  }, {
    "nav-left": _withCtx(() => [
      _createVNode(_component_etd_logo, { height: 30 })
    ]),
    "nav-right": _withCtx(() => [
      _createVNode(_component_helpdesk_button)
    ]),
    "nav-extended": _withCtx(() => [
      _createVNode(_component_slider_progress)
    ]),
    main: _withCtx(() => [
      (!_ctx.isLoaded)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_etd_loading)
          ]))
        : _createCommentVNode("", true),
      (_ctx.isLoaded && !_ctx.error)
        ? (_openBlock(), _createBlock(_component_slider, { key: 1 }))
        : _createCommentVNode("", true),
      (_ctx.isLoaded && _ctx.error)
        ? (_openBlock(), _createBlock(_component_etd_form_error_message, { key: 2 }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.error.response.data.message), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    fixed: _withCtx(() => [
      _createVNode(_component_etd_pagewrap, { "enable-padding-horizontal": false }, {
        default: _withCtx(() => [
          _createVNode(_component_slider_tools)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["stacked"]))
}