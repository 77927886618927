import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createSlots as _createSlots } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { id: "authorize" }
const _hoisted_7 = ["innerHTML"]

import HelpdeskButton from "@/components/elements/HelpdeskButton.vue";
import { useRoute } from "vue-router";
import { computed, inject } from "vue";
import { usePersonalInfoStore } from "@/store/personal-info";
import { etdConsts } from "@innouveau/123-etd/dist/consts/consts";


export default /*@__PURE__*/_defineComponent({
  __name: 'PaymentSuccess',
  setup(__props) {

const route = useRoute();
const showAuthorization = route.query.machtigen === "true";
const educatorCity = route.query.stad;
const educatorId = route.query.opleider;

const mq = inject("mq");
const personalInfoStore = usePersonalInfoStore();

const routeName = computed(() => route.name);

const email = computed(() => personalInfoStore.form.email);
const examsUrl = window.config.videoUrl + "examens";
const videoUrl = window.config.videoUrl;

return (_ctx: any,_cache: any) => {
  const _component_etd_button = _resolveComponent("etd-button")!
  const _component_etd_container = _resolveComponent("etd-container")!
  const _component_etd_page_message = _resolveComponent("etd-page-message")!
  const _component_etd_card = _resolveComponent("etd-card")!
  const _component_etd_authorize = _resolveComponent("etd-authorize")!
  const _component_etd_footer = _resolveComponent("etd-footer")!
  const _component_etd_page = _resolveComponent("etd-page")!

  return (_openBlock(), _createBlock(_component_etd_page, {
    "main-full-width": "",
    "hide-intro": "",
    "enable-logo": "",
    "enable-header": "",
    stacked: _unref(mq).t === 's'
  }, _createSlots({
    "nav-right": _withCtx(() => [
      _createVNode(HelpdeskButton)
    ]),
    header: _withCtx(() => [
      _createVNode(_component_etd_page_message, {
        type: "success",
        title: "Betaling gelukt"
      }, {
        default: _withCtx(() => [
          (
                        routeName.value === 'PaymentOnlineSuccess' ||
                        routeName.value === 'PaymentOfflineSuccess'
                    )
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                innerHTML: 
                        _ctx.$t('pages.payment.success.firstSubscription.body', {
                            email: email.value,
                            telephone: _unref(etdConsts).contact.telephone.label,
                        })
                    
              }, null, 8, _hoisted_1))
            : _createCommentVNode("", true),
          (routeName.value === 'PaymentUpsellExamSuccess')
            ? (_openBlock(), _createBlock(_component_etd_container, {
                key: 1,
                gap: "xxl"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", {
                    innerHTML: _ctx.$t('pages.payment.success.upsellExam.body')
                  }, null, 8, _hoisted_2),
                  _createElementVNode("div", null, [
                    _createVNode(_component_etd_button, {
                      href: examsUrl,
                      target: "_self"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t(
                                        "pages.payment.success.upsellExam.button"
                                    )), 1)
                      ]),
                      _: 1
                    })
                  ])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (routeName.value === 'PaymentUpsellUpgradeSuccess')
            ? (_openBlock(), _createBlock(_component_etd_container, {
                key: 2,
                gap: "xxl"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", {
                    innerHTML: 
                                _ctx.$t('pages.payment.success.upsellUpgrade.body')
                            
                  }, null, 8, _hoisted_3),
                  _createElementVNode("div", null, [
                    _createVNode(_component_etd_button, {
                      href: _unref(videoUrl),
                      target: "_self"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t(
                                        "pages.payment.success.upsellUpgrade.button"
                                    )), 1)
                      ]),
                      _: 1
                    }, 8, ["href"])
                  ])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (routeName.value === 'PaymentReExamSuccess')
            ? (_openBlock(), _createElementBlock("div", {
                key: 3,
                innerHTML: 
                            _ctx.$t('pages.payment.success.reExam.body', {
                                email: email.value,
                            })
                        
              }, null, 8, _hoisted_4))
            : _createCommentVNode("", true),
          (routeName.value === 'PaymentUpsellExtendSuccess')
            ? (_openBlock(), _createBlock(_component_etd_container, {
                key: 4,
                gap: "xxl"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", {
                    innerHTML: 
                                _ctx.$t('pages.payment.success.upsellExtend.body')
                            
                  }, null, 8, _hoisted_5),
                  _createElementVNode("div", null, [
                    _createVNode(_component_etd_button, {
                      href: _unref(videoUrl),
                      target: "_self"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t(
                                        "pages.payment.success.upsellExtend.button"
                                    )), 1)
                      ]),
                      _: 1
                    }, 8, ["href"])
                  ])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    "full-width": _withCtx(() => [
      _createVNode(_component_etd_footer, {
        stacked: _unref(mq).t === 's'
      }, null, 8, ["stacked"])
    ]),
    _: 2
  }, [
    showAuthorization
      ? {
          name: "main",
          fn: _withCtx(() => [
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_etd_card, {
                type: _unref(mq).t === 's' ? 'transparent' : 'regular',
                padding: "xxxxl"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_etd_card, {
                    type: _unref(mq).t === 's' ? 'regular' : 'transparent'
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_etd_container, { gap: "xxxl" }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", null, [
                            _createElementVNode("h2", null, _toDisplayString(_ctx.$t("pages.authorize.head")), 1),
                            _createElementVNode("div", {
                              innerHTML: _ctx.$t('pages.authorize.body')
                            }, null, 8, _hoisted_7)
                          ])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["type"]),
                  _createVNode(_component_etd_card, {
                    type: _unref(mq).t === 's' ? 'regular' : 'transparent'
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_etd_authorize, {
                        "educator-city": _unref(educatorCity),
                        "educator-id": _unref(educatorId)
                      }, null, 8, ["educator-city", "educator-id"])
                    ]),
                    _: 1
                  }, 8, ["type"])
                ]),
                _: 1
              }, 8, ["type"])
            ])
          ]),
          key: "0"
        }
      : undefined
  ]), 1032, ["stacked"]))
}
}

})