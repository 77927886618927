import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_helpdesk_button = _resolveComponent("helpdesk-button")!
  const _component_etd_page_message = _resolveComponent("etd-page-message")!
  const _component_etd_footer = _resolveComponent("etd-footer")!
  const _component_etd_page = _resolveComponent("etd-page")!

  return (_openBlock(), _createBlock(_component_etd_page, {
    "main-full-width": "",
    "hide-intro": "",
    "enable-logo": "",
    "enable-header": "",
    "header-background": "pink-7",
    stacked: _ctx.mq.t === 's'
  }, {
    "nav-right": _withCtx(() => [
      _createVNode(_component_helpdesk_button)
    ]),
    header: _withCtx(() => [
      _createVNode(_component_etd_page_message, {
        type: "unknown",
        title: "Status onbekend"
      }, {
        default: _withCtx(() => [
          _cache[0] || (_cache[0] = _createTextVNode(" We kunnen de status van de betaling momenteel niet achterhalen.")),
          _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1)),
          _createTextVNode(" Voor vragen, neem contact op via " + _toDisplayString(_ctx.etdConsts.contact.telephone.label), 1)
        ]),
        _: 1
      })
    ]),
    "full-width": _withCtx(() => [
      _createVNode(_component_etd_footer, {
        stacked: _ctx.mq.t === 's'
      }, null, 8, ["stacked"])
    ]),
    _: 1
  }, 8, ["stacked"]))
}