import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { ref } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'AddDiscount',
  emits: ["add-discount"],
  setup(__props, { emit: __emit }) {

const emit = __emit;

const showDiscount = ref(false);

const openDiscount = () => (showDiscount.value = true);

const formValue = ref({
    code: "",
});

const formStatus = ref(false);

const atUpdateStatus = (status: boolean) => (formStatus.value = status);

const applyCode = () => {
    emit("add-discount", formValue.value.code);
};

const formConfig = {
    settings: {
        displayRequiredMark: false,
        displayPositiveFeedback: true,
        validateAt: "blur",
    },
    cards: [
        {
            type: "transparent",
            sections: [
                {
                    type: "regular",
                    rows: [
                        {
                            items: [
                                {
                                    key: "code",
                                    label: "Kortingscode",
                                    descriptionBefore:
                                        "Voer hieronder jouw kortingscode in",
                                    type: "text",
                                    editable: true,
                                    required: true,
                                    validation: [
                                        {
                                            hint: "Dit veld mag niet leeg zijn",
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    ],
};

return (_ctx: any,_cache: any) => {
  const _component_etd_button = _resolveComponent("etd-button")!
  const _component_etd_form = _resolveComponent("etd-form")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_etd_button, {
      onClick: openDiscount,
      variant: "secondary",
      "icon-id": "gift",
      "icon-color": "blue-5",
      "icon-style": "duotone",
      size: "big"
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t("pages.checkout.addDiscount")), 1)
      ]),
      _: 1
    }),
    _withDirectives(_createElementVNode("div", null, [
      _createVNode(_component_etd_form, {
        formValue: formValue.value,
        "onUpdate:formValue": _cache[0] || (_cache[0] = ($event: any) => ((formValue).value = $event)),
        "form-config": formConfig,
        onUpdateStatus: atUpdateStatus
      }, {
        submit: _withCtx(() => [
          _createVNode(_component_etd_button, {
            id: "submit-discount",
            onClick: applyCode,
            disabled: !formStatus.value
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("apply")), 1)
            ]),
            _: 1
          }, 8, ["disabled"])
        ]),
        _: 1
      }, 8, ["formValue"])
    ], 512), [
      [_vShow, showDiscount.value]
    ])
  ], 64))
}
}

})