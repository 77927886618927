import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "Dates" }
const _hoisted_2 = { "data-locator": "slide-head" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { id: "date-details" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_date_methods = _resolveComponent("date-methods")!
  const _component_slide_body = _resolveComponent("slide-body")!
  const _component_etd_container = _resolveComponent("etd-container")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_etd_container, { gap: "xxxl" }, {
      default: _withCtx(() => [
        (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.mq.t === 's' ? 'div' : 'etd-card'), {
          padding: "xxxxl",
          "border-radius": "xl"
        }, {
          default: _withCtx(() => [
            _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.$t(_ctx.entry + "head")), 1),
            _createElementVNode("div", {
              innerHTML: _ctx.$t(_ctx.entry + 'body')
            }, null, 8, _hoisted_3),
            _createVNode(_component_slide_body, null, {
              default: _withCtx(() => [
                _createVNode(_component_date_methods, {
                  onSelect: _ctx.atSelectDateMethod,
                  "date-methods": _ctx.data.dateMethods,
                  selection: _ctx.localDateMethod
                }, null, 8, ["onSelect", "date-methods", "selection"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })),
        (_ctx.showDateDetail)
          ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.mq.t === 's' ? 'div' : 'etd-card'), {
              key: 0,
              id: "pick-date-detail",
              padding: "xxxxl",
              "border-radius": "xl"
            }, {
              default: _withCtx(() => [
                _cache[0] || (_cache[0] = _createElementVNode("a", {
                  id: "date-picker-details",
                  style: {"scroll-margin-top":"140px"}
                }, null, -1)),
                _createElementVNode("div", _hoisted_4, [
                  (_ctx.localDateMethod)
                    ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.dateDetailComponentName), {
                        key: _ctx.localDateMethod.slug,
                        onSelect: _ctx.atSelectDateDetail,
                        options: _ctx.data.dateDetailItems[_ctx.localDateMethod.slug],
                        "date-method-key": _ctx.localDateMethod.slug + 's',
                        selection: _ctx.localDateDetails
                      }, null, 40, ["onSelect", "options", "date-method-key", "selection"]))
                    : _createCommentVNode("", true)
                ])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}