import { defineStore } from "pinia";
import { UiState } from "@/types";
import { CURRENT_TEST_GROUPS } from "@/data/constants";
import { enableTesting } from "@/data/config";

const getTestGroup = () => {
    const standardUser = CURRENT_TEST_GROUPS[0];
    const deviationUser = CURRENT_TEST_GROUPS[1];
    if (enableTesting) {
        return Math.random() > 0.5 ? deviationUser : standardUser;
    } else {
        return "no-test";
    }
};

export const useUiStore = defineStore("ui", {
    state: () => {
        const state: UiState = {
            displayHelpdeskPopup: false,
            currentSlide: 0,
            group: getTestGroup(),
        };
        return state;
    },
    getters: {
        isTestUser: (state: UiState) => {
            return state.group === CURRENT_TEST_GROUPS[1];
        },
    },
    actions: {
        nextSlide() {
            this.currentSlide++;
        },
        previousSlide() {
            this.currentSlide--;
        },
        jumpToSlide(s: number) {
            this.currentSlide = s;
        },
    },
});
