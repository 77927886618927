import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { style: {"text-align":"center"} }
const _hoisted_2 = { "data-subscription-id": "" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_helpdesk_button = _resolveComponent("helpdesk-button")!
  const _component_subscription = _resolveComponent("subscription")!
  const _component_etd_footer = _resolveComponent("etd-footer")!
  const _component_etd_page = _resolveComponent("etd-page")!

  return (_openBlock(), _createBlock(_component_etd_page, {
    "main-full-width": "",
    "hide-intro": "",
    "enable-logo": "",
    "enable-header": "",
    "nav-floating": "",
    stacked: _ctx.mq.t === 's'
  }, _createSlots({
    "nav-right": _withCtx(() => [
      _createVNode(_component_helpdesk_button)
    ]),
    main: _withCtx(() => [
      _createVNode(_component_subscription, { onResponse: _ctx.atResponseSubscriptionData }, null, 8, ["onResponse"])
    ]),
    "full-width": _withCtx(() => [
      _createVNode(_component_etd_footer, {
        stacked: _ctx.mq.t === 's'
      }, null, 8, ["stacked"])
    ]),
    _: 2
  }, [
    (_ctx.response)
      ? {
          name: "header",
          fn: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("h2", null, _toDisplayString(_ctx.response.checkout_header_title), 1),
              _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.response.reference_text), 1)
            ])
          ]),
          key: "0"
        }
      : undefined
  ]), 1032, ["stacked"]))
}